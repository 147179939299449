import { getusertrail } from "../../config/config";

export const Getusertrail_FETCH_STARTED = "ProductsData_FETCH_STARTED";
export const Getusertrail_FETCH_SUCCESS = "ProductsData_FETCH_SUCCESS";
export const Getusertrail_FETCH_ERROR = "ProductsData_FETCH_ERROR";

const usertrailActions = {

  getusertrail: (data) => async (dispatch) => {
    try {
      dispatch({
        type: Getusertrail_FETCH_STARTED,
      });

      const res = await getusertrail(data);

      if (res) {
        dispatch({
          type: Getusertrail_FETCH_SUCCESS,
          payload: res
        });
      } else {
        dispatch({ type: Getusertrail_FETCH_ERROR });
      }
      return res;
      
    } catch (error) {
      console.log(error);
      dispatch({
        type: Getusertrail_FETCH_ERROR,
        payload: {
          error: "error getting getusertrials api",
        },
      });
    }
  },
};

export default usertrailActions;
